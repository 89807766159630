



























































































































































































.form-component {
}

.inputPrice input[type='number'] {
    -moz-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
