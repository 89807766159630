












































































































@import '../scss/variables';

.v-card {
    border-radius: $borderradius !important;
    user-select: none;
}

.account-card {
    .content {
        height: 100%;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            filter: opacity(0.5);
        }

        .content-inner {
            position: relative;
        }
    }

    .turn {
        transform: rotate(-45deg);
    }

    .min {
        // min-width: 275px;
    }

    .border::after {
        content: '';
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // border: 2px solid ;

        box-shadow: inset 0px 0px 0px 4px var(--primary-color);
        box-sizing: border-box;
        border-radius: $borderradius;
    }

    .overlay-card {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        font-weight: 400;
        white-space: normal;
    }
}

.copybutton {
    position: absolute;
    right: 0px;
    border-radius: 0 $borderradius;
    z-index: 1;
}

.drag {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    height: 40px;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    margin-left: -4px;

    div {
        width: 8px;
        height: 4px;
        border-radius: 0 2px 2px 0;
        background: lightgrey;
    }
}
