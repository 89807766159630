



























































.offer-text {
    font-size: 16px;
}
