


























































































































































.to-dialog {
}
.v-btn--active {
    background-color: var(--accent-color) !important;
    color: white !important;
    border: none !important;
}

.wallet-card {
    cursor: pointer;
}
.remove-margin {
    margin: 0px !important;
}
