
























































































































































































































































































header {
    background: lighten(#2d4052, 20);
    color: white;
}

@media screen and (max-width: 600px) {
}

@media screen and (min-width: 960px) {
}

.payment-item:last-child {
    margin-bottom: 5rem;
}

.date {
    span {
        color: grey;
        font-size: 0.8rem;
        padding-left: 1.5rem;
    }

    hr {
        border-width: 2px 0 0 0;
    }
}
