




































































































































































































































































































































.create-wallet {
    .v-btn-toggle {
        background-color: transparent !important;
    }
    .v-btn--active {
        background-color: var(--accent-color) !important;
        color: white !important;
        border: none !important;
    }
    .v-expansion-panel {
        background-color: transparent !important;
    }
}
