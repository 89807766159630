

















































@mixin item($color) {
    border-left: 0.5rem solid $color;
    .amount {
        color: lighten($color, 10) !important;
    }
}

.Buy {
    @include item(#2b6fb6);

    .sell {
        color: #f3002a !important;
    }

    .buy {
        color: #21ca21 !important;
    }
}
