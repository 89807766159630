


































































































.payment-header {
    background: #4a5c70;
    color: white;
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.out {
        background-color: var(--accent-color);
    }
    &.in {
        background-color: #ff4a4a;
    }
}
