
















































































































@import './scss/variables';

.theme--light.v-application {
    background-color: #f5f5f5 !important;
}

.version {
    bottom: 9px;
    left: 5px;
    position: fixed;
    z-index: 1000;
    font-size: 0.6em;
    color: #bdbdbd;
    text-transform: uppercase;
}

.banner {
    bottom: 9px;
    left: -40px;
    transform: rotate(45deg);
    position: fixed;
    z-index: 1000;
    background: rgba(255, 0, 0, 0.45);
    padding: 5px 40px;
}

.app {
    .loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--primary-color);
        z-index: 99;
    }

    .history-card {
        .v-btn {
            i.v-icon {
                position: static !important;
            }
        }
    }

    .v-card,
    .v-dialog {
        overflow: hidden;

        .badge-top {
            border-radius: 0 $borderradius;
        }

        .badge-bottom {
            border-radius: $borderradius 0;

            &.v-btn {
                z-index: 2;

                i.v-icon {
                    top: -7px;
                    position: relative;
                }
            }
        }

        .badge- * {
            position: relative;
            top: -50px;
        }

        &.border {
            .badge-top {
                border-radius: 0 0 0 $borderradius;
            }

            .badge-bottom {
                border-radius: $borderradius 0 0 0;
            }
        }

        .v-expansion-panel__header {
            margin: 0 !important;
            padding: 0 !important;
        }

        .v-expansion-panel__container {
            background: none;
        }
    }

    .v-dialog {
        border-radius: 0px !important;
    }

    .v-dialog .v-card {
        border-radius: 0px !important;
    }

    .v-card__text .wallet-selector {
        overflow: hidden;
    }

    .v-main > .content {
        min-height: 100%;
    }

    .fill-height {
        min-height: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    .cheat {
        // position: absolute;
        // top: -$borderradius;
        // border-radius: 0 0 $borderradius $borderradius;
        // background: var(--primary-color);
        // height: 95px;
        // width: 100%;
        // z-index: 0;
        // left: 0;
    }

    .scrollable {
        height: 0px;
        overflow: auto;
    }

    .clickable {
        cursor: pointer;
    }

    .round {
        border-radius: $borderradius !important;
        overflow: hidden;
    }

    .v-timeline-item {
        flex-direction: row;
    }

    .v-timeline--dense .v-timeline-item__dot--large {
        position: sticky;
        align-self: flex-start;
        margin-left: -7px;
        margin-right: 22px;
    }

    .transfer {
        .cam {
            width: 100%;
            height: calc(100% - (56px + 30px));
            overflow: hidden;
            max-width: none !important;
            position: relative;

            .inside {
                position: relative;
                height: 100%;
                width: 100%;

                video {
                    width: auto;
                    height: 100%;
                    overflow: hidden;
                    max-width: none !important;
                    max-height: none !important;
                    left: 50%;
                    position: relative;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .inputNumber input[type='number'] {
        -moz-appearance: textfield;
    }

    .inputNumber input::-webkit-outer-spin-button,
    .inputNumber input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .noborder {
        border: none;
        border-width: 0px !important;
    }

    .v-select-list {
        border-radius: 0;
    }
}
