



































.system {
    border-left: 0.5rem solid lightslategray;
}
