




































section {
    margin: auto;
    width: 100%;
    padding: 15%;
    margin-top: 1rem;
    text-align: center;
    align-self: center;
    transform: scale(1.2);
    display: inline-block;
}
