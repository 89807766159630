





























































.subbatitle {
    font-size: 36px;
}
.main-icon {
    padding-left: 20px;
    &:before {
        opacity: 0 !important;
    }
    .v-icon {
        font-size: 37px !important;
    }
}

.toolbar {
    height: 90px;
}

.toolbar {
    position: sticky;
    top: 0;
    z-index: 99;
}
