





























































































































































































































































































.vcard {
    margin: 4px;
}

.dev-view {
    z-index: 500;
}
