





























































































































































































































































































































































































































































.transfer {
    .v-btn-toggle {
        background-color: transparent;
    }

    .v-btn--active {
        background-color: var(--accent-color) !important;
        color: white !important;
        border: none;
    }

    input {
        // display: none;
        background-color: green;
    }
}

.v-select__selections > input[type='text'] {
    display: none;
}
