











































































































































































































































header {
    background: lighten(#2d4052, 20);
    color: white;
}

.address {
    font-size: 2.4vw;
    word-break: break-all;
}

@media screen and (max-width: 600px) {
    .address {
        font-size: 0.875rem;
    }
}

@media screen and (min-width: 960px) {
    .address {
        font-size: 23px;
    }
}

.payment-item:last-child {
    margin-bottom: 5rem;
}

.info-payments {
    display: block;
    text-align: center;
    color: rgb(168, 168, 168);
    padding-bottom: 2rem;
    position: absolute;
    bottom: 0;
    width: 100vw;
}

.date {
    span {
        color: grey;
        font-size: 0.8rem;
        padding-left: 1.5rem;
    }

    hr {
        border-width: 2px 0 0 0;
    }
}

.details {
    display: flex;
    flex-direction: column;
}

.v-tabs {
    display: flex;
    flex-direction: column;
}

.v-tabs-items,
.infinite-loading-container {
    background: #f5f5f5 !important;
}

.asset-list {
    padding-bottom: 124px;
}

.asset-list:after {
    display: block;
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    background: linear-gradient(#00000000, #ffffff);
    width: 100%;
    height: 130px;
    pointer-events: none;
}
