















































































header {
    background: lighten(#2d4052, 20);
    color: white;
}

@media screen and (max-width: 600px) {
}

@media screen and (min-width: 960px) {
}

.asset-list {
    padding-bottom: 124px;
}

.asset-list:after {
    display: block;
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    background: linear-gradient(#00000000, #ffffff);
    width: 100%;
    height: 130px;
    pointer-events: none;
}
