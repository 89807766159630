







































@mixin item($color) {
    border-left: 0.5rem solid $color;
    .amount {
        color: darken($color, 40) !important;
    }
}

.item {
    @include item(grey);
}
